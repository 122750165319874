import {request} from "./BaseApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { User } from "Apis/Models/User.type";

declare var Intercom: any;

export const USERS_CACHE_KEY = 'USERS_CACHE_KEY';
export const useUsers = () => {
    return useQuery(USERS_CACHE_KEY, async () => {
        return await request<User[]>({
            url: `/users`,
            method: 'GET'
        });
    })
}

export const useSaveUser = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (user: User): Promise<User> => {
        if (user.id) {
            return await request<User>({
                url: `/users/${user.id}`,
                method: 'PUT',
                data: user
            })
        } else {
            return await request<User>({
                url: `/users`,
                method: 'POST',
                data: user
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(USERS_CACHE_KEY);

            Intercom('trackEvent', 'User-Updated');
        }
    })
}

export const useDeleteUser = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (id: number) => {
        return await request({
            url: `/users/${id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(USERS_CACHE_KEY);
        }
    })
}

export const useDeactivateUser = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (id: number) => {
        return await request({
            url: `/users/${id}/deactivate`,
            method: 'PUT'
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(USERS_CACHE_KEY);
        }
    })
}

import {request} from "./BaseApi";
import {Customer} from "./Models/Customer.type";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {openSuccessNotification} from "Utils/NotificationUtils";
import {useTranslation} from "react-i18next";

declare var Intercom: any;

export const CUSTOMERS_CACHE_KEY = 'CUSTOMERS_CACHE_KEY';
export const useCustomers = () => {
    return useQuery(CUSTOMERS_CACHE_KEY, async () => {
        return await request<Customer[]>({
            url: `/customer`,
            method: 'GET'
        });
    })
}

export const CUSTOMER_CACHE_KEY = 'CUSTOMER_CACHE_KEY';
export const useCustomer = (id: number) => {
    return useQuery([CUSTOMER_CACHE_KEY, id], async () => {
        return await request<Customer>({
            url: `/customer/${id}`,
            method: 'GET'
        })
    })
}

export const useSaveCustomer = () => {
    const { t } = useTranslation();
    
    const queryClient = useQueryClient();

    return useMutation(async (customer: Customer): Promise<Customer> => {
        if (customer.id) {
            return await request<Customer>({
                url: `/customer/${customer.id}`,
                method: 'PUT',
                data: customer
            })
        } else {
            return await request<Customer>({
                url: `/customer`,
                method: 'POST',
                data: customer
            })
        }
    }, {
        onSuccess: () => {
            openSuccessNotification(t('success'), t('customers:customerSaved'))
            queryClient.invalidateQueries(CUSTOMER_CACHE_KEY);
            queryClient.invalidateQueries(CUSTOMERS_CACHE_KEY)
            Intercom('trackEvent', 'Customer-Updated');
        }
    })
}

export const useDeleteCustomer = () => {
    const queryClient = useQueryClient();
    
    const { t } = useTranslation();

    return useMutation(async (customer: Customer) => {
        return await request<Customer>({
            url: `/customer/${customer.id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: () => {
            openSuccessNotification(t('success'), t('customers:customerDeleted'))
            queryClient.invalidateQueries(CUSTOMER_CACHE_KEY);
            queryClient.invalidateQueries(CUSTOMERS_CACHE_KEY)
        }
    })
}
